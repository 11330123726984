import React from "react";
import styled from "styled-components";
import { getFlex } from "../../styles/styles";
import { useTranslation } from "gatsby-plugin-react-i18next";

const TermsSection = (props) => {
  const { className, title, desc, nameSpace} = props;
  const { t } = useTranslation(nameSpace || "policy");

  return (
    <section className={className}>
      <h2>{t(title)}</h2>
      <p>{t(desc)}</p>
    </section>
  );
};

export default styled(TermsSection)`
  padding: 4% 10%;
  ${getFlex("column", "sb", "ct")};
  row-gap: 2rem;
  h2 {
    font-size: 3.2rem;
    text-align: start;
    width: 100%;
  }
  p {
    font-size: 2rem;
    line-height: 4rem;
    font-family: SofiaProRegularAz, sans-serif;
    text-align: start;
    width: 100%;
  }
`;
