import React from "react";
import styled from "styled-components";
import Layout from "../comps/reusable/Layout";
import { graphql } from "gatsby";
import HeroSection from "../comps/reusable/HeroSection";
import TermsSection from "../comps/terms-and-conditions/TermsSection";
import PreFooter from "../comps/reusable/Pre-footer";

const Pledge = () => {

  return (
    <Layout>
      <HeroSection
        data={{
          imageName: "heroPrivacy.png",
          hasButtons: false,
        }}
        nameSpace="policy"
      />
      <TermsSection title="termsFirstTitle" desc="termsFirstDesc" />
      <TermsSection title="termsSecondTitle" desc="termsSecondDesc" />
      <TermsSection title="termsThirdTitle" desc="termsThirdDesc" />
      <TermsSection title="termsFourthTitle" desc="termsFourthDesc" />
      <TermsSection title="termsTitle5" desc="termsDesc5" />
      <TermsSection title="termsTitle6" desc="termsDesc6" />
      <TermsSection title="termsTitle7" desc="termsDesc7" />
      <TermsSection title="termsTitle8" desc="termsDesc8" />
      <TermsSection title="termsTitle9" desc="termsDesc9" />
      <TermsSection title="termsTitle10" desc="termsDesc10" />
      <TermsSection title="termsTitle11" desc="termsDesc11" />
      <TermsSection title="termsTitle12" desc="termsDesc12" />
      <PreFooter nameSpace="terms" />
    </Layout>
  );
};

export default styled(Pledge)``;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
